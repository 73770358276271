.App {
  width: 1200px;
  height: 100vh;
  margin: 0 auto;
}

.ant-layout {
  opacity: 0.9;
}
.ant-layout-footer {
  line-height: 50px;
  background-color: #000;
  color: #fff;
}